import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { getBeneficiary } from 'app/features/beneficiaries/beneficiarySaga';

export default function Beneficiary({ beneficiaryId }) {
  const dispatch = useDispatch();

  const { getCountryNameByIso3Code } = useCountries();

  const viewBeneficiaryData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Beneficiary Name',
            get: (row) =>
              [row?.first_name, row?.middle_name, row?.last_name]
                .filter((v) => !isEmpty(v))
                .map((v) => ucwords(v))
                .join(' '),
          },
          {
            label: 'Beneficiary Type',
            data: 'beneficiary_type',
          },
          {
            label: 'Customer',
            data: 'user_name',
            get: ({ user_id, user_name }) => (
              <CustomerClickAwayDetails customerId={user_id} label={user_name} />
            ),
          },
          {
            label: 'Customer ID',
            data: 'user_id',
          },
          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Partner ID',
            data: 'partner_id',
          },
          {
            label: 'Phone Country Code',
            data: 'phone_country_code',
          },
          {
            label: 'Mobile Number',
            data: 'mobile_number',
          },
          {
            label: 'Country',
            data: 'country',
            get: (row) => getCountryNameByIso3Code(row?.country),
          },
          {
            label: 'Post Code',
            data: 'postcode',
          },
          {
            label: 'City',
            data: 'city',
          },
          {
            label: 'State',
            data: 'state',
          },
          {
            label: 'Address',
            data: 'address',
          },
          {
            label: 'Relation',
            data: 'relation',
          },
          {
            label: 'Payment Type',
            data: 'payment_type',
          },
          {
            label: 'Currency',
            data: 'currency',
          },
          {
            label: 'Account Number',
            data: 'account_number',
          },
          {
            label: 'Bank Identifier Type',
            data: 'bank_identifier_type',
          },
          {
            label: 'Bank Identifier Value',
            data: 'bank_identifier_value',
          },
          {
            label: 'Branch Identifier Type',
            data: 'branch_identifier_type',
          },
          {
            label: 'Branch Identifier Value',
            data: 'branch_identifier_value',
          },
          {
            label: 'ID Type',
            data: 'id_type',
          },
          {
            label: 'ID Number',
            data: 'id_number',
          },
          {
            label: 'ID Issued Date',
            data: 'id_issue_date',
          },
          {
            label: 'ID Expiry Date',
            data: 'id_expiry_date',
          },
          {
            label: 'ID Issued Country',
            data: 'id_issued_country',
          },
          {
            label: 'Source ID',
            data: 'source_id',
          },
          {
            label: 'Account ID',
            data: 'account_id',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isActive={!!is_verified} />,
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: beneficiary,
  } = useSelector((state) => state.beneficiaries.show);

  useEffect(() => {
    dispatch(getBeneficiary(beneficiaryId));
  }, [beneficiaryId]);

  return (
    // <FormContainer>
    <Grid container spacing={2}>
      {viewBeneficiaryData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={beneficiary}
          isLoading={isLoading}
        />
      ))}
    </Grid>
    // </FormContainer>
  );
}

Beneficiary.propTypes = {
  beneficiaryId: PropTypes.string,
};
