import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import FormContainer from 'core/forms/FormContainer';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import DateUtils from 'utils/DateUtils';
import KycStatus from 'core/badges/KycStatus';

export default function CustomerDetailsTab() {
  const { isLoading, data: customer } = useSelector((state) => state.customers.show);

  const viewCustomerData = useMemo(
    () => [
      {
        groupName: 'Basic Information',
        groupData: [
          {
            label: 'First Name',
            data: 'first_name',
          },
          {
            label: 'Last Name',
            data: 'last_name',
          },
          {
            label: 'Date of birth',
            data: 'dob',
            get: ({ dob }) => (dob ? DateUtils.isoToString(dob, false) : ''),
          },
          {
            label: 'Email',
            data: 'email',
          },
          {
            label: 'Mobile Number',
            data: 'mobile_number',
          },
        ],
      },
      {
        groupName: 'Address Information',
        groupData: [
          {
            label: 'Post Code',
            data: 'post_code',
          },
          {
            label: 'Unit',
            data: 'unit',
          },
          {
            label: 'Street',
            data: 'street',
          },
          {
            label: 'Street Number',
            data: 'street_number',
          },
          {
            label: 'Street Type',
            data: 'street_type',
          },
          {
            label: 'Suburb',
            data: 'suburb',
          },
          {
            label: 'State',
            data: 'state',
          },
          {
            label: 'Country',
            data: 'country',
          },
        ],
      },
      {
        groupName: 'Others Information',
        groupData: [
          {
            label: 'Occupation',
            data: 'occupation',
          },
          {
            label: 'KYC Status',
            get: ({ kyc_status }) => <KycStatus status={kyc_status} />,
          },
          {
            label: 'ID Type',
            data: 'id_type',
          },
          {
            label: 'ID Number',
            data: 'id_number',
          },
          {
            label: 'ID Issued By',
            data: 'id_issued_by',
          },
          {
            label: 'ID Expiry Date',
            data: 'id_expire_date',
            get: ({ id_expire_date }) =>
              id_expire_date ? DateUtils.isoToString(id_expire_date, false) : '',
          },
          {
            label: 'IP Address',
            data: 'ip_address',
          },

          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner ',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: "Partner ID",
            data: "partner_id",
          },
          {
            label: 'Source ID',
            data: 'source_id',
          },
          {
            label: 'Account ID',
            data: 'account_id',
          },
          {
            label: 'Nationality',
            data: 'nationality',
          },
          {
            label: 'Birth Country',
            data: 'birth_country',
          },
          {
            label: 'Source of Income',
            data: 'source_of_income',
          },
          {
            label: 'Is Verified ?',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },

          {
            label: 'Card Number',
            data: 'card_number',
          },
          {
            label: 'Created At',
            get: ({ created_ts }) => (created_ts ? DateUtils.isoToString(created_ts) : ''),
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) => (updated_ts ? DateUtils.isoToString(updated_ts) : ''),
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  return (
    <FormContainer>
      <Grid container spacing={2}>
        {viewCustomerData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={customer}
            isLoading={isLoading}
          />
        ))}
      </Grid>
    </FormContainer>
  );
}
