/**
 * KYC config
 *
 * Below config values must be same as the values in the database
 *
 */

export const kycMethods = {
  onfido: 'onfido',
  greenid: 'greenid',
  manual: 'manual',
  shuftipro: 'shuftipro',
  jumio: 'jumio',
  paascheck: 'paascheck',
};

export const kycTypes = {
  ekyc: 'ekyc',
};

export const kycStatuses = {
  created: 'created',
  pending: 'pending',
  processing: 'processing',
  completed: 'completed',
  rejected: 'rejected',
  expired: 'expired',
};

/**
 *
 * KYC options setup is for html select
 */

export const kycMethodOptions = [
  { value: kycMethods.onfido, label: 'Onfido' },
  { value: kycMethods.greenid, label: 'GreenID' },
  { value: kycMethods.manual, label: 'Manual' },
  { value: kycMethods.shuftipro, label: 'ShuftiPro' },
  { value: kycMethods.jumio, label: 'Jumio' },
  { value: kycMethods.paascheck, label: 'PaaScheck' },
];

export const kycTypeOptions = [{ value: kycTypes.ekyc, label: 'eKYC' }];

export const kycStatusOptions = [
  { value: kycStatuses.created, label: 'Created' },
  { value: kycStatuses.pending, label: 'Pending' },
  { value: kycStatuses.processing, label: 'Processing' },
  { value: kycStatuses.completed, label: 'Completed' },
  { value: kycStatuses.rejected, label: 'Rejected' },
  { value: kycStatuses.expired, label: 'Expired' },
];
